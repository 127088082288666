import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  useRoutes,
  useParams
} from 'react-router-dom';
import Confetti from 'react-confetti';

function ImageRoute() {
  let { filename } = useParams();
  const imageUrl = filename.endsWith('.png') ? `/certificates/${filename}` : `/certificates/${filename}.png`;

  return (
    <div className="App" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={500} recycle={false} />
      <header className="App-header" style={{ width: '100%', height: '100%' }}>
        <img src={imageUrl} alt={filename} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      </header>
    </div>
  );
}

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

function AppRoutes() {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/:filename", element: <ImageRoute /> }
  ]);
  return routes;
}

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
